import { getCurrentUser } from "vuefire";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const user = await getCurrentUser();

  if (!user) {
    return navigateTo({
      path: "/auth/login",
      // This allos me to store the path the user was trying to access so that once they log in, I can redirect them back to where they were trying to access
      query: {
        redirect: to.fullPath,
      },
    });
  }

  const results = await user.getIdTokenResult(true);

  if (!results.claims.isSystemAdmin && !results.claims.isAdmin) {
    return navigateTo({
      path: "/",
    });
  }

  useAdminQuickComments().streamQuickComments();
});
